const Instructions = [
    { Name: "Muster Armies", Content: "Data" },
    { Name: "Mission Briefing", Content: "Data" },
    { Name: "Create the Battlefield", Content: "Data" },
    { Name: "Deploy Forces", Content: "Data" },
    { Name: "First Turn", Content: "Data" },
    { Name: "Pre-battle Rules", Content: "Data" },
    { Name: "Battle Start", Content: "Data" },
    { Name: "Battle End", Content: "Data" },
    { Name: "Determine the Victor", Content: "Data" },
]

export default Instructions;