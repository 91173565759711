import React from 'react'
import { Routes, Route } from "react-router-dom"
import  NotFound from '../../Pages/NotFound'

import Core from './Pages/Core'
import Simulation from './Pages/Simulation'

function GameStart() {
    return (
        <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path='/' element={<Core />} />
            <Route path='/simulation' element={<Simulation />} />
        </Routes>
    )
}

export default GameStart