import React from 'react'
import { Routes, Route } from "react-router-dom"
import  NotFound from '../../Pages/NotFound'

import Core from './Pages/Core'
import Simulation from './Pages/Simulation'

import Characteristics from './Pages/Characteristics'
import DamageTables from './Pages/DamageTables'
import Keywords from './Pages/Keywords'
import Wargear from './Pages/Wargear'
import Weapons from './Pages/Weapons'
import Abilities from './Pages/Abilities'
import Composition from './Pages/Composition'
import Profiles from './Pages/Profiles'
import Rating from './Pages/Rating'
import Role from './Pages/Role'
import Name from './Pages/Name'

function Datasheet() {
    return (
        <Routes>
            <Route path='/characteristics' element={<Characteristics />} />
            <Route path='/damageTables' element={<DamageTables />} />
            <Route path='/keywords' element={<Keywords />} />
            <Route path='/wargear' element={<Wargear />} />
            <Route path='/weapons' element={<Weapons />} />
            <Route path='/abilities' element={<Abilities />} />
            <Route path='/composition' element={<Composition />} />
            <Route path='/profiles' element={<Profiles />} />
            <Route path='/rating' element={<Rating />} />
            <Route path='/role' element={<Role />} />
            <Route path='/name' element={<Name />} />
            <Route path='/simulation' element={<Simulation />} />
            <Route path='/' element={<Core />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}

export default Datasheet