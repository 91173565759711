import React, { useRef, useState } from 'react'
import Swal from 'sweetalert2'
import Header from '../Components/Header'
import api from '../Helpers/API'

const VALID_CLASS = 'is-valid'
const INVALID_CLASS = 'is-invalid'

function ValidateEmail(email = '') {
    if (email.length < 6) return false  // a@b.eu 
    const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
    return regex.test(email)
}

function ValidateUser(user = '') {
    if (user.length < 2) return false  // AS 
    return true
}

function ValidatePass(pass = '') {
    if (pass.length < 8) return false 
    return true
}

function Register() {
    const [ userValid, setUserValid ] = useState('')
    const [ emailValid, setEmailValid ] = useState('')
    const [ passValid, setPassValid ] = useState('')
    const name = useRef();
    const email = useRef();
    const pass = useRef();

    function HandleSubmit() {
        // Collect Data
        const User = name.current.value ?? ''
        const Email = email.current.value ?? ''
        const Pass = pass.current.value ?? ''
        
        // Collect Validation
        const UserValid = ValidateUser(User)
        const EmailValid = ValidateEmail(Email)
        const PassValid = ValidatePass(Pass)

        // Apply Errors
        setUserValid(UserValid ? VALID_CLASS : INVALID_CLASS) 
        setEmailValid(EmailValid ? VALID_CLASS : INVALID_CLASS) 
        setPassValid(PassValid ? VALID_CLASS : INVALID_CLASS)         
        if (!UserValid || !EmailValid || !PassValid) return;
        console.log("Is Valid", EmailValid, UserValid, PassValid)
        
        api({ func: "register", data: { Email, User, Pass  } }).then(data => {
            console.log(data)
            if (data.success) return window.location.pathname = "/login"
            else Swal.fire({ title: "Registration Failed", text: data.message, icon: 'error' }) 
        })
    }

    return (
        <>
            <Header text={<div className='text-center'>Register</div>} />
            <div data-bs-theme="dark">
                <div className="mb-3 row">
                    <label className="col-sm-2 col-form-label text-end">
                        Name
                    </label>
                    <div className="col-sm-10">
                        <input type="email" ref={name} className={`form-control ${userValid}`} />
                        <div className="valid-feedback">
                            Welcome BROTHER
                        </div>
                        <div className="invalid-feedback">
                            Your name must be at least 2 characters long.
                        </div>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label className="col-sm-2 col-form-label text-end">
                        Email
                    </label>
                    <div className="col-sm-10">
                        <input type="email" ref={email} className={`form-control ${emailValid}`} />
                        <div className="invalid-feedback">
                            We need a real email to validate your account! <br/>
                            <em> Example: user@email.com </em>
                        </div>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label text-end">
                        Password
                    </label>
                    <div className="col-sm-10">
                        <input type="password" ref={pass} className={`form-control ${passValid}`}/>
                        <div className="invalid-feedback">
                            Passwords must be at least 8 characters long!
                        </div>
                    </div>
                </div>
                <div className='text-center mt-4'>
                    <button type="submit" className="btn btn-outline-success col-6" onClick={HandleSubmit}>Submit</button>
                </div>
            </div>
        </>
    )
}

export default Register