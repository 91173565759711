import React from 'react'
import { Routes, Route } from "react-router-dom"
import  NotFound from '../../Pages/NotFound'

import Core from './Pages/Core'
import Simulation from './Pages/Simulation'
import Faction from './Pages/Faction'

function Factions() {
    return (
        <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path='/' element={<Core />} />
            <Route path='/simulation' element={<Simulation />} />
            <Route path='/:id' element={<Faction />} />
        </Routes>
    )
}

export default Factions