import React from 'react'
import Header from '../Components/Header'
import CollectionItem from '../Components/CollectionItem'

function Collections() {
    return (
        <>
            <Header text={"Collections"} />
            <div>
                <CollectionItem name={'Joey Item'} description={'Made By Ian to Get you Started'} to={'/collection/0'} />
            </div>
        </>
    )
}

export default Collections