const GameStart = {
    Name: "Game Start",
    Core: '/starting',
    Pages: [
        { 
            Name: 'Simulation', 
            Path: '/simulation',
            Description: ''
        },
    ],
};

export default GameStart