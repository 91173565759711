const TOKEN_ROOT = 'stratagem-jdeldev'

function Get() {
    return JSON.parse(localStorage.getItem(`${TOKEN_ROOT}-token`))
}

function Set(data) {
    return localStorage.setItem(`${TOKEN_ROOT}-token`, JSON.stringify(data))
}

function Reset() {
    return localStorage.removeItem(`${TOKEN_ROOT}-token`)
}

const Token = {
    Get, Set, Reset
}

export default Token