import React from 'react'
import { Link } from 'react-router-dom'

function Section({ title, content, link }) {
    return (
        <div className="card text-center">
        <div className="card-header">
            <h5 className="card-title">{title}</h5>
        </div>
            <img src={`https://placehold.jp/000000/ffffff/200x200.png?text=${title.replaceAll(" ", "%20")}`} className="card-img-top" alt="..." />
            <div className="card-body">
                <p className="card-text">{content}</p>
            </div>
            <div className="card-footer text-body-secondary">
                <Link to={link}> Learn More... </Link>
            </div>
        </div>
    )
}

export default Section