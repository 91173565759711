const Phases = {
    Name: "Phases",
    Core: '/phases',
    Pages: [
        { 
            Name: '1. Command Phase',    
            Path: '/command', 
            Description: "Both players muster strategic resources and use tactical abilities.",
        },
        { 
            Name: '2. Movement Phase',   
            Path: '/movement', 
            Description: "Your units manoeuvre across the battlefield.",
        },
        { 
            Name: '3. Psychic Phase',    
            Path: '/psychic', 
            Description: "Your psykers use powerful mental abilities.",
        },
        { 
            Name: '4. Shooting Phase',   
            Path: '/shooting', 
            Description: "Your units shoot enemy units.",
        },
        { 
            Name: '5. Charge Phase',     
            Path: '/charge', 
            Description: "Your units may move into close combat with enemy units.",
        },
        { 
            Name: '6. Fight Phase',      
            Path: '/fight', 
            Description: "Both players' units pile in and attack with melee weapons.",
        },
        { 
            Name: '7. Morale Phase',     
            Path: '/morale', 
            Description: "Both players test the courage of their depleted units.",
        },
        { 
            Name: 'Simulation', 
            Path: '/simulation',
            Description: 'Simulate Each Phase In Game Order'
        },
    ],
    Data: {
        Phases: [
            "Command",
            "Movement",
            "Psychic",
            "Shooting",
            "Charge",
            "Fight",
            "Morale"
        ]
    }
};

export default Phases;