import React from 'react'
import Header from '../../../Components/Header'

function Missions() {
    return (
        <div>
            <Header text={"Missions"} link={'/general/missions'} />
            
        </div>
    )
}

export default Missions