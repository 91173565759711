const General = {
    Name: "General Rules",
    Core: '/general',
    Pages: [
        { 
            Name: 'Missions', 
            Path: '/missions', 
            Description: ""
        },
        { 
            Name: 'Army', 
            Path: '/army', 
            Description: ""
        },
        { 
            Name: 'Battlefield', 
            Path: '/battlefield', 
            Description: ""
        },
        { 
            Name: 'Measuring', 
            Path: '/measuring', 
            Description: ""
        },
        { 
            Name: 'Dice', 
            Path: '/dice', 
            Description: ""
        },
        { 
            Name: 'Start vs Half Strength', 
            Path: '/halfstrength', 
            Description: ""
        },
    ],
};

export default General