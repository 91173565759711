import React from 'react';
import NavBar from './sections/NavBar';
import RouteController from './sections/RouteController';
import Footer from './sections/Footer';


function App() {
  return (
    <>
      <NavBar />
      <div className='container mt-5'>
        <RouteController />
      </div> 
      <Footer />
    </> 
  )
}

export default App