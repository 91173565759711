import React from 'react'
import Header from '../Components/Header'
import api from '../Helpers/API'
import { useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'

function Validate() {
    const [ query ] = useSearchParams()
    const Token = query.get("t")
    const Email = query.get("e")

    async function validate() {
        api({ func: 'validate', data: { Email, Token } }).then(({ success, message }) => {
            console.log("Running")
            if (!success) return Swal.fire({ icon: 'error', title: 'Validation Failed', text: message }).then(window.location.reload)
            else window.location.href = window.location.origin + '/login'
        }).catch(e => Swal.fire({ icon: 'error', title: 'Validation Failed' }))
    } 
    validate()
    return (
        <div className='text-center'>
            <Header text={<>Validate Account</>} />
            <br />

            <div className="spinner-border my-5" role="status">
                <span className="visually-hidden">Working...</span>
            </div>
        </div>
    )
}

export default Validate