import React from 'react'
import Header from '../../../Components/Header'

function Command() {
    return (
        <div>
            <Header text={"Command Phase"} link={'/phases/command'} />
            <div>
                Both players muster strategic resources and use tactical abilities. <br />
            </div>
            <h2>Battle-forged CP bonus</h2>
            <div>
                <strong> If your army is Battle-forged: </strong> <u>Gain 1 CP</u> before doing any anything
            </div>
            <h2>Other References</h2>
            <div>
                <ol>
                    <li> Some Abilities (Review Datasheets) are used in the Command phase </li>
                    <li> Some Stratagems are used in the Command phase </li>
                    <li> Resolve These After the <u>Battle-Forged CP Bonus</u> </li>
                </ol>
            </div>
        </div>
    )
}

export default Command