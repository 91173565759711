import React from 'react'
import Header from '../../../Components/Header'
import ReRolls from '../Sections/ReRolls'
import RollOffs from '../Sections/RollOffs'
import Sequencing from '../Sections/Sequencing'

function Dice() {
    return (
        <div>
            <Header text={"Dice"} link={'/general/dice'} />
            <div>
                Data
            </div> <br />

            <ReRolls /> <br />
            <RollOffs /> <br />
            <Sequencing /> <br />
        </div>
    )
}

export default Dice