import React from "react";

function DUMMYITEM() {
	return (
		<div className="bg-light battlescribe">
			<div className="battlescribe">
				<h1>Joey List (Warhammer 40,000 9th Edition) [102 PL, 1,960pts, 12CP]</h1>
				<ul>
					<li className="force">
						<h2>Battalion Detachment 0CP (Orks) [102 PL, 1,960pts, 12CP]</h2>
						<p className="rule-names">
							<span className="bold">Rules:</span> <span className="italic">Dis Is Ours! Zog Off!</span>
						</p>
						<ul>
							<li className="category">
								<h3>Configuration [12CP]</h3>
								<ul>
									<li className="rootselection">
										<h4>Battle Size [12CP]</h4>
										<p>
											<span className="bold">Selections:</span> 3. Strike Force (101-200 Total PL / 1001-2000 Points) [12CP]
										</p>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Configuration</span>
										</p>
									</li>
									<li className="rootselection">
										<h4>Clan Kultur / Specialist Mobs</h4>
										<p>
											<span className="bold">Selections:</span> No Clan / Specialist Mob
										</p>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Configuration</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">No Clan</span>
										</p>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">No Clan</td>
												<td>You can take units from multiple Clans and Mobs, but you lose access to particular Clan Kultures and Subkulturs</td>
											</tr>
										</table>
									</li>
									<li className="rootselection">
										<h4>Detachment Command Cost</h4>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Configuration</span>
										</p>
									</li>
									<li className="rootselection">
										<h4>Game Type</h4>
										<p>
											<span className="bold">Selections:</span> Open
										</p>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Configuration</span>
										</p>
									</li>
								</ul>
							</li>
							<li className="category">
								<h3>HQ [10 PL, 220pts]</h3>
								<ul>
									<li className="rootselection">
										<h4>Big Mek in Mega Armour [6 PL, 130pts]</h4>
										<p>
											<span className="bold">Selections:</span> Grot Oiler [5pts], Kustom Force Field [20pts], Kustom Mega-blasta [10pts], Power Klaw [10pts]
										</p>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Big Mek, Character, Faction: &lt;Clan&gt;, Faction: Ork, HQ, Infantry, Mega Armour, Gretchin, Grot Oiler</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka, Mob Rule</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Big Mekaniak, Grot Oiler, Kustom Force Field</span>, <span className="bold">Unit:</span> <span className="italic">Big Mek in Mega Armour, Grot Oiler</span>, <span className="bold">Weapon:</span> <span className="italic">Kustom Mega-blasta, Power Klaw</span>
										</p>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Big Mekaniak</td>
												<td>At the end of your Movement phase, this model can repair a single friendly &lt;CLAN&gt; VEHICLE model within 3&quot;. That model regains D3 lost wounds. A model can only be repaired once per turn.</td>
											</tr>
											<tr>
												<td className="profile-name">Grot Oiler</td>
												<td>
													Once per battle, a Grot Oiler can assist its master in making repairs. If it does so, the model being repaired regains 1 additional lost wound.
													<br />
													<br />
													When rolling to wound this unit, use the Big Mek’s Toughness while it is on the battlefield. The death of a Grot Oiler is ignored for the purposes of Morale tests. The Grot Oiler is considered to have the CHARACTER keyword for the purposes of shooting attacks.
												</td>
											</tr>
											<tr>
												<td className="profile-name">Kustom Force Field</td>
												<td>If this model is equipped with a kustom force field, friendly ORK units have a 5+ invulnerable save against attacks made with ranged weapons while they are wholly within 9&quot; of it. While a model equipped with a kustom force field is embarked, the vehicle transporting it has a 5+ invulnerable save against attacks made with ranged weapons instead. </td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Big Mek in Mega Armour</td>
												<td>4&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>5</td>
												<td>4</td>
												<td>5</td>
												<td>3</td>
												<td>8</td>
												<td>2+</td>
											</tr>
											<tr>
												<td className="profile-name">Grot Oiler</td>
												<td>5&quot;</td>
												<td>5+</td>
												<td>4+</td>
												<td>2</td>
												<td>2</td>
												<td>1</td>
												<td>1</td>
												<td>4</td>
												<td>6+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Kustom Mega-blasta</td>
												<td>24&quot;</td>
												<td>Assault 1</td>
												<td>8</td>
												<td>-3</td>
												<td>D6</td>
												<td>If you roll one or more hit rolls of 1, the bearer suffers a mortal wound after all of the weapon&apos;s shots have been resolved</td>
											</tr>
											<tr>
												<td className="profile-name">Power Klaw</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>x2</td>
												<td>-3</td>
												<td>D3</td>
												<td>When attacking with this weapon, you must subtract 1 from the hit roll</td>
											</tr>
										</table>
									</li>
									<li className="rootselection">
										<h4>Warboss [4 PL, 90pts]</h4>
										<p>
											<span className="bold">Selections:</span> Kombi-weapon with rokkit launcha [10pts], Power Klaw [10pts], 2x Slugga, Stikkbombs, Warlord
										</p>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Character, Infantry, Warboss, HQ, Faction: &lt;Clan&gt;, Faction: Ork, Warlord</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka, Mob Rule</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Breakin&apos; Heads, Kombi-weapon with rokkit launcha, Waaagh!</span>, <span className="bold">Unit:</span> <span className="italic">Warboss</span>, <span className="bold">Weapon:</span> <span className="italic">Power Klaw, Rokkit launcha, Shoota, Slugga, Stikkbomb</span>
										</p>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Breakin&apos; Heads</td>
												<td>If a &lt;CLAN&gt; unit fails a Morale test within 3&quot; of a friendly &lt;CLAN&gt; WARBOSS, they can restore order with a brutal display of violence. If they do, the unit suffers D3 mortal wounds, but the Morale test is then considered to have been passed.</td>
											</tr>
											<tr>
												<td className="profile-name">Kombi-weapon with rokkit launcha</td>
												<td>When attacking with this weapon, choose one or both of the profiles below. If you choose both, subtract 1 from all hit rolls.</td>
											</tr>
											<tr>
												<td className="profile-name">Waaagh!</td>
												<td>Friendly &lt;CLAN&gt; Infantry units within 6&quot; of this model at the start of the Charge phase can charge even if they Advanced this turn.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Warboss</td>
												<td>5&quot;</td>
												<td>2+</td>
												<td>5+</td>
												<td>6</td>
												<td>5</td>
												<td>6</td>
												<td>4</td>
												<td>8</td>
												<td>4+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Power Klaw</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>x2</td>
												<td>-3</td>
												<td>D3</td>
												<td>When attacking with this weapon, you must subtract 1 from the hit roll</td>
											</tr>
											<tr>
												<td className="profile-name">Rokkit launcha</td>
												<td>24&quot;</td>
												<td>Assault 1</td>
												<td>8</td>
												<td>-2</td>
												<td>3</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Shoota</td>
												<td>18&quot;</td>
												<td>Assault 2</td>
												<td>4</td>
												<td>0</td>
												<td>1</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Slugga</td>
												<td>12&quot;</td>
												<td>Pistol 1</td>
												<td>4</td>
												<td>0</td>
												<td>1</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Stikkbomb</td>
												<td>6&quot;</td>
												<td>Grenade D6</td>
												<td>3</td>
												<td>0</td>
												<td>1</td>
												<td>Blast</td>
											</tr>
										</table>
									</li>
								</ul>
							</li>
							<li className="category">
								<h3>Troops [18 PL, 370pts]</h3>
								<ul>
									<li className="rootselection">
										<h4>Boyz [8 PL, 160pts]</h4>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Boyz, Infantry, Faction: Ork, Faction: &lt;Clan&gt;, Troops</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka, Mob Rule</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Green Tide</span>
										</p>
										<ul>
											<li>
												<h4>20x Ork Boy W/ Shoota [160pts]</h4>
												<p>
													<span className="bold">Selections:</span> 20x Shoota, 20x Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Ork Boy</span>, <span className="bold">Weapon:</span> <span className="italic">Shoota, Stikkbomb</span>
												</p>
											</li>
										</ul>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Green Tide</td>
												<td>If this unit includes 20 or more models, add 1 to the Attacks characteristic of each model in the unit.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Ork Boy</td>
												<td>5&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>4</td>
												<td>4</td>
												<td>1</td>
												<td>2</td>
												<td>6</td>
												<td>6+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Shoota</td>
												<td>18&quot;</td>
												<td>Assault 2</td>
												<td>4</td>
												<td>0</td>
												<td>1</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Stikkbomb</td>
												<td>6&quot;</td>
												<td>Grenade D6</td>
												<td>3</td>
												<td>0</td>
												<td>1</td>
												<td>Blast</td>
											</tr>
										</table>
									</li>
									<li className="rootselection">
										<h4>Boyz [8 PL, 160pts]</h4>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Boyz, Infantry, Faction: Ork, Faction: &lt;Clan&gt;, Troops</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka, Mob Rule</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Green Tide</span>
										</p>
										<ul>
											<li>
												<h4>20x Ork Boy W/ Slugga &amp; Choppa [160pts]</h4>
												<p>
													<span className="bold">Selections:</span> 20x Choppa, 20x Slugga, 20x Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Ork Boy</span>, <span className="bold">Weapon:</span> <span className="italic">Choppa, Slugga, Stikkbomb</span>
												</p>
											</li>
										</ul>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Green Tide</td>
												<td>If this unit includes 20 or more models, add 1 to the Attacks characteristic of each model in the unit.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Ork Boy</td>
												<td>5&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>4</td>
												<td>4</td>
												<td>1</td>
												<td>2</td>
												<td>6</td>
												<td>6+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Choppa</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>user</td>
												<td>0</td>
												<td>1</td>
												<td>Each time the bearer fights, it can make 1 additional attack with this weapon.</td>
											</tr>
											<tr>
												<td className="profile-name">Slugga</td>
												<td>12&quot;</td>
												<td>Pistol 1</td>
												<td>4</td>
												<td>0</td>
												<td>1</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Stikkbomb</td>
												<td>6&quot;</td>
												<td>Grenade D6</td>
												<td>3</td>
												<td>0</td>
												<td>1</td>
												<td>Blast</td>
											</tr>
										</table>
									</li>
									<li className="rootselection">
										<h4>Gretchin [2 PL, 50pts]</h4>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Faction: &lt;Clan&gt;, Faction: Ork, Gretchin, Infantry, Troops</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">Dakka Dakka Dakka, Grots</span>
										</p>
										<ul>
											<li>
												<h4>10x Gretchin [50pts]</h4>
												<p>
													<span className="bold">Selections:</span> 10x Grot Blaster
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Gretchin</span>, <span className="bold">Weapon:</span> <span className="italic">Grot Blaster</span>
												</p>
											</li>
										</ul>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Gretchin</td>
												<td>5&quot;</td>
												<td>5+</td>
												<td>4+</td>
												<td>2</td>
												<td>2</td>
												<td>1</td>
												<td>1</td>
												<td>4</td>
												<td>6+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Grot Blaster</td>
												<td>12&quot;</td>
												<td>Pistol 1</td>
												<td>3</td>
												<td>0</td>
												<td>1</td>
												<td>-</td>
											</tr>
										</table>
									</li>
								</ul>
							</li>
							<li className="category">
								<h3>Elites [15 PL, 270pts]</h3>
								<ul>
									<li className="rootselection">
										<h4>Meganobz [6 PL, 120pts]</h4>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Elites, Faction: &lt;Clan&gt;, Faction: Ork, Infantry, Mega Armour, Meganob, Nobz</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka, Mob Rule</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Keepin&apos; Order</span>
										</p>
										<ul>
											<li>
												<h4>Boss Meganob w/ two kill saws [2 PL, 40pts]</h4>
												<p>
													<span className="bold">Selections:</span> Killsaws (Pair) [15pts], Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Boss Meganob</span>, <span className="bold">Weapon:</span> <span className="italic">Killsaw, Stikkbomb</span>
												</p>
											</li>
											<li>
												<h4>Meganob w/ 2 kill saws [2 PL, 40pts]</h4>
												<p>
													<span className="bold">Selections:</span> Killsaws (Pair) [15pts], Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Meganob</span>, <span className="bold">Weapon:</span> <span className="italic">Killsaw, Stikkbomb</span>
												</p>
											</li>
											<li>
												<h4>Meganob w/ 2 kill saws [2 PL, 40pts]</h4>
												<p>
													<span className="bold">Selections:</span> Killsaws (Pair) [15pts], Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Meganob</span>, <span className="bold">Weapon:</span> <span className="italic">Killsaw, Stikkbomb</span>
												</p>
											</li>
										</ul>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Keepin&apos; Order</td>
												<td>While a &lt;CLAN&gt; unit is within 3&quot; of any friendly &lt;CLAN&gt; units with this ability, each time a model would flee that unit as a result of a failed Morale or Combat Attrition test, roll one D6: on a 6, that model does not flee.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Boss Meganob</td>
												<td>4&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>5</td>
												<td>4</td>
												<td>3</td>
												<td>3</td>
												<td>7</td>
												<td>2+</td>
											</tr>
											<tr>
												<td className="profile-name">Meganob</td>
												<td>4&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>5</td>
												<td>4</td>
												<td>3</td>
												<td>3</td>
												<td>6</td>
												<td>2+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Killsaw</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>x2</td>
												<td>-4</td>
												<td>2</td>
												<td>When attacking with this weapon, you must subtract 1 from the hit roll. If a model is equipped with two killsaws, add 1 to its Attacks characteristic.</td>
											</tr>
											<tr>
												<td className="profile-name">Stikkbomb</td>
												<td>6&quot;</td>
												<td>Grenade D6</td>
												<td>3</td>
												<td>0</td>
												<td>1</td>
												<td>Blast</td>
											</tr>
										</table>
									</li>
									<li className="rootselection">
										<h4>Nobz [6 PL, 85pts]</h4>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Elites, Faction: &lt;Clan&gt;, Faction: Ork, Infantry, Nobz</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka, Mob Rule</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Keepin&apos; Order</span>
										</p>
										<ul>
											<li>
												<h4>Boss Nob [17pts]</h4>
												<p>
													<span className="bold">Selections:</span> Choppa, Slugga, Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Boss Nob</span>, <span className="bold">Weapon:</span> <span className="italic">Choppa, Slugga, Stikkbomb</span>
												</p>
											</li>
											<li>
												<h4>Nob [17pts]</h4>
												<p>
													<span className="bold">Selections:</span> Choppa, Slugga, Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Nob</span>, <span className="bold">Weapon:</span> <span className="italic">Choppa, Slugga, Stikkbomb</span>
												</p>
											</li>
											<li>
												<h4>Nob [17pts]</h4>
												<p>
													<span className="bold">Selections:</span> Choppa, Slugga, Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Nob</span>, <span className="bold">Weapon:</span> <span className="italic">Choppa, Slugga, Stikkbomb</span>
												</p>
											</li>
											<li>
												<h4>Nob [17pts]</h4>
												<p>
													<span className="bold">Selections:</span> Choppa, Slugga, Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Nob</span>, <span className="bold">Weapon:</span> <span className="italic">Choppa, Slugga, Stikkbomb</span>
												</p>
											</li>
											<li>
												<h4>Nob [17pts]</h4>
												<p>
													<span className="bold">Selections:</span> Choppa, Slugga, Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Nob</span>, <span className="bold">Weapon:</span> <span className="italic">Choppa, Slugga, Stikkbomb</span>
												</p>
											</li>
										</ul>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Keepin&apos; Order</td>
												<td>While a &lt;CLAN&gt; unit is within 3&quot; of any friendly &lt;CLAN&gt; units with this ability, each time a model would flee that unit as a result of a failed Morale or Combat Attrition test, roll one D6: on a 6, that model does not flee.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Boss Nob</td>
												<td>5&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>5</td>
												<td>4</td>
												<td>2</td>
												<td>3</td>
												<td>7</td>
												<td>4+</td>
											</tr>
											<tr>
												<td className="profile-name">Nob</td>
												<td>5&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>5</td>
												<td>4</td>
												<td>2</td>
												<td>3</td>
												<td>6</td>
												<td>4+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Choppa</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>user</td>
												<td>0</td>
												<td>1</td>
												<td>Each time the bearer fights, it can make 1 additional attack with this weapon.</td>
											</tr>
											<tr>
												<td className="profile-name">Slugga</td>
												<td>12&quot;</td>
												<td>Pistol 1</td>
												<td>4</td>
												<td>0</td>
												<td>1</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Stikkbomb</td>
												<td>6&quot;</td>
												<td>Grenade D6</td>
												<td>3</td>
												<td>0</td>
												<td>1</td>
												<td>Blast</td>
											</tr>
										</table>
									</li>
									<li className="rootselection">
										<h4>Painboy [3 PL, 65pts]</h4>
										<p>
											<span className="bold">Selections:</span> &apos;Urty Syringe, Power Klaw
										</p>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Character, Faction: &lt;Clan&gt;, Faction: Ork, Infantry, Elites, Painboy</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka, Mob Rule</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Dok&apos;s Tools, Grot Orderly, Sawbonez</span>, <span className="bold">Unit:</span> <span className="italic">Painboy</span>, <span className="bold">Weapon:</span> <span className="italic">&apos;Urty Syringe, Power Klaw</span>
										</p>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Dok&apos;s Tools</td>
												<td>Roll a D6 each time a &lt;CLAN&gt; INFANTRY or &lt;CLAN&gt; BIKER unit loses a wound whilst within 3&quot; of any friendly &lt;CLAN&gt; PAINBOYZ. On a 6, that unit does not lose that wound.</td>
											</tr>
											<tr>
												<td className="profile-name">Grot Orderly</td>
												<td>Once per battle, you can re-roll the dice when this model is attempting to heal a model using the Sawbonez ability, either when determining if the surgery is successful or when determining the number of lost wounds regained.</td>
											</tr>
											<tr>
												<td className="profile-name">Sawbonez</td>
												<td>At the end of your Movement phase, this model can attempt surgery on a single friendly &lt;CLAN&gt; INFANTRY or BIKER model within 1&quot; of it. If it does so, roll a D6 to determine if the surgery is successful. On a 1 the surgery fails and the model you were attempting to heal loses a wound. On a 2+ the surgery succeeds and that model regains D3 lost wounds. A model can only be the target of a surgery attempt once per turn.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Painboy</td>
												<td>5&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>5</td>
												<td>4</td>
												<td>4</td>
												<td>4</td>
												<td>6</td>
												<td>6+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">&apos;Urty Syringe</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>User</td>
												<td>0</td>
												<td>1</td>
												<td>Each time the bearer fights, it can make 1 additional attack with this weapon. This weapon always wounds on a 4+ unless it is targeting a VEHICLE or TITANIC model, in which case it wounds on a 6+.</td>
											</tr>
											<tr>
												<td className="profile-name">Power Klaw</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>x2</td>
												<td>-3</td>
												<td>D3</td>
												<td>When attacking with this weapon, you must subtract 1 from the hit roll</td>
											</tr>
										</table>
									</li>
								</ul>
							</li>
							<li className="category">
								<h3>Fast Attack [18 PL, 295pts]</h3>
								<ul>
									<li className="rootselection">
										<h4>Nobz on Warbikes [10 PL, 125pts]</h4>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Faction: &lt;Clan&gt;, Faction: Ork, Biker, Speed Freeks, Nobz on Warbikes, Fast Attack</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka, Mob Rule</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Keepin&apos; Order</span>
										</p>
										<ul>
											<li>
												<h4>Boss Nob on Warbike [35pts]</h4>
												<p>
													<span className="bold">Selections:</span> Big Choppa [5pts], 2x Dakkagun, Slugga
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Boss Nob on Bike</span>, <span className="bold">Weapon:</span> <span className="italic">Big Choppa, Dakkagun, Slugga</span>
												</p>
											</li>
											<li>
												<h4>Nob on Warbike [30pts]</h4>
												<p>
													<span className="bold">Selections:</span> Choppa, 2x Dakkagun
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Nob on Warbike</span>, <span className="bold">Weapon:</span> <span className="italic">Choppa, Dakkagun</span>
												</p>
											</li>
											<li>
												<h4>Nob on Warbike [30pts]</h4>
												<p>
													<span className="bold">Selections:</span> Choppa, 2x Dakkagun
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Nob on Warbike</span>, <span className="bold">Weapon:</span> <span className="italic">Choppa, Dakkagun</span>
												</p>
											</li>
											<li>
												<h4>Nob on Warbike [30pts]</h4>
												<p>
													<span className="bold">Selections:</span> Choppa, 2x Dakkagun
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Nob on Warbike</span>, <span className="bold">Weapon:</span> <span className="italic">Choppa, Dakkagun</span>
												</p>
											</li>
										</ul>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Keepin&apos; Order</td>
												<td>While a &lt;CLAN&gt; unit is within 3&quot; of any friendly &lt;CLAN&gt; units with this ability, each time a model would flee that unit as a result of a failed Morale or Combat Attrition test, roll one D6: on a 6, that model does not flee.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Boss Nob on Bike</td>
												<td>14&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>5</td>
												<td>5</td>
												<td>3</td>
												<td>3</td>
												<td>7</td>
												<td>4+</td>
											</tr>
											<tr>
												<td className="profile-name">Nob on Warbike</td>
												<td>14&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>5</td>
												<td>5</td>
												<td>3</td>
												<td>3</td>
												<td>6</td>
												<td>4+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Big Choppa</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>+2</td>
												<td>-1</td>
												<td>2</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Choppa</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>user</td>
												<td>0</td>
												<td>1</td>
												<td>Each time the bearer fights, it can make 1 additional attack with this weapon.</td>
											</tr>
											<tr>
												<td className="profile-name">Dakkagun</td>
												<td>18&quot;</td>
												<td>Assault 3</td>
												<td>5</td>
												<td>0</td>
												<td>1</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Slugga</td>
												<td>12&quot;</td>
												<td>Pistol 1</td>
												<td>4</td>
												<td>0</td>
												<td>1</td>
												<td>-</td>
											</tr>
										</table>
									</li>
									<li className="rootselection">
										<h4>Shokkjump Dragstas [5 PL, 110pts]</h4>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Faction: &lt;Clan&gt;, Faction: Ork, Vehicle, Fast Attack, Speed Freeks, Shokkjump Dragsta</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka, Mob Rule, Speed Mob</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Explodes, Grot Gunner and Targeting Squig, Shokk tunnel</span>
										</p>
										<ul>
											<li>
												<h4>Shokkjump Dragstas [5 PL, 110pts]</h4>
												<p>
													<span className="bold">Selections:</span> Kustom Shokk Rifle, Rokkit Launcha, Saw Blades
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Shokkjump Dragstas</span>, <span className="bold">Weapon:</span> <span className="italic">Kustom Shokk Rifle, Rokkit launcha, Saw Blades</span>
												</p>
											</li>
										</ul>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Explodes</td>
												<td>If this model is reduced to 0 wounds, roll a D6 before removing it from the battlefield and before any embarked models disembark. On a 6 it explodes, and each unit within 3&quot; suffers D3 mortal wound.</td>
											</tr>
											<tr>
												<td className="profile-name">Grot Gunner and Targeting Squig</td>
												<td>Each time this model makes an attack with its kustom shokk rifle, this model has a BS characteristic of 3+ for that attack.</td>
											</tr>
											<tr>
												<td className="profile-name">Shokk tunnel</td>
												<td>If you roll a 4+ when advancing with this model, remove it from the battlefield, and set it up more than 9&quot; away from enemy units. After doing so, roll a D6 on a 6, the model suffers 1 mortal wound.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Shokkjump Dragstas</td>
												<td>14&quot;</td>
												<td>4+</td>
												<td>5+</td>
												<td>5</td>
												<td>6</td>
												<td>8</td>
												<td>4</td>
												<td>6</td>
												<td>4+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Kustom Shokk Rifle</td>
												<td>24&quot;</td>
												<td>Assault 2</td>
												<td>8</td>
												<td>-3</td>
												<td>D6</td>
												<td>If you roll one or more unmodified hit rolls of 1 for this weapon, the bearer suffers 1 mortal wound after all of this weapon&apos;s attacks have been resolved. Each time you make a wound roll of 6+ for this weapon, the target suffers 1 mortal wound in addition to any other damage.</td>
											</tr>
											<tr>
												<td className="profile-name">Rokkit launcha</td>
												<td>24&quot;</td>
												<td>Assault 1</td>
												<td>8</td>
												<td>-2</td>
												<td>3</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Saw Blades</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>+1</td>
												<td>-1</td>
												<td>1</td>
												<td></td>
											</tr>
										</table>
									</li>
									<li className="rootselection">
										<h4>Stormboyz [3 PL, 60pts]</h4>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Faction: Ork, Faction: &lt;Clan&gt;, Fast Attack, Stormboy, Infantry, Jump Pack, Fly</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka, Mob Rule</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Full Throttle, Stormboyz Strike</span>
										</p>
										<ul>
											<li>
												<h4>Boss Nob [12pts]</h4>
												<p>
													<span className="bold">Selections:</span> Choppa, Slugga, Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Boss Nob</span>, <span className="bold">Weapon:</span> <span className="italic">Choppa, Slugga, Stikkbomb</span>
												</p>
											</li>
											<li>
												<h4>4x Stormboy [48pts]</h4>
												<p>
													<span className="bold">Selections:</span> 4x Choppa, 4x Slugga, 4x Stikkbombs
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Stormboy</span>, <span className="bold">Weapon:</span> <span className="italic">Choppa, Slugga, Stikkbomb</span>
												</p>
											</li>
										</ul>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Full Throttle</td>
												<td>When this unit advances you can add 6&quot; to its movement rather than rolling a dice. But if you do so, you roll a D6 for each model in the unit at the end of the phase; for each roll of 1, the unit suffers 1 mortal wound.</td>
											</tr>
											<tr>
												<td className="profile-name">Stormboyz Strike</td>
												<td>During deployment, you can set up this unit flying high in the skies instead of placing it on the battlefield. At the end of any of your Movement phases this unit can plummet onto the battlefield - set them up anywhere on the battlefield, more than 9&quot; away from any enemy models.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Boss Nob</td>
												<td>12&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>5</td>
												<td>4</td>
												<td>2</td>
												<td>3</td>
												<td>7</td>
												<td>6+</td>
											</tr>
											<tr>
												<td className="profile-name">Stormboy</td>
												<td>12&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>4</td>
												<td>4</td>
												<td>1</td>
												<td>2</td>
												<td>6</td>
												<td>6+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Choppa</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>user</td>
												<td>0</td>
												<td>1</td>
												<td>Each time the bearer fights, it can make 1 additional attack with this weapon.</td>
											</tr>
											<tr>
												<td className="profile-name">Slugga</td>
												<td>12&quot;</td>
												<td>Pistol 1</td>
												<td>4</td>
												<td>0</td>
												<td>1</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Stikkbomb</td>
												<td>6&quot;</td>
												<td>Grenade D6</td>
												<td>3</td>
												<td>0</td>
												<td>1</td>
												<td>Blast</td>
											</tr>
										</table>
									</li>
								</ul>
							</li>
							<li className="category">
								<h3>Heavy Support [31 PL, 600pts]</h3>
								<ul>
									<li className="rootselection">
										<h4>Battlewagon [9 PL, 150pts]</h4>
										<p>
											<span className="bold">Selections:</span> &apos;ard Case, Zzap gun [15pts]
										</p>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Faction: &lt;Clan&gt;, Faction: Ork, Transport, Vehicle, Battlewagon, Heavy Support</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">Dakka Dakka Dakka</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">&apos;ard Case, Explodes</span>, <span className="bold">Transport:</span> <span className="italic">Battlewagon</span>, <span className="bold">Transport Wound Track:</span> <span className="italic">Battlewagon1, Battlewagon2, Battlewagon3</span>, <span className="bold">Unit:</span> <span className="italic">Battlewagon (&apos;ard Case)</span>, <span className="bold">Weapon:</span>{" "}
											<span className="italic">Zzap gun</span>
										</p>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">&apos;ard Case</td>
												<td>If this model is equipped with an &apos;ard case, its Toughness characteristic is increased by 1 and it loses the Open-topped ability.</td>
											</tr>
											<tr>
												<td className="profile-name">Explodes</td>
												<td>If this model is reduced to 0 wounds, roll a D6 before removing it from the battlefield and before any embarked models disembark. On a 6 it explodes, and each unit within 6&quot; suffers D6 mortal wounds.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Transport</th>
												<th>Capacity</th>
											</tr>
											<tr>
												<td className="profile-name">Battlewagon</td>
												<td>This model can transport 20 FLASH GITZ or &lt;CLAN&gt; INFANTRY models. Each MEGA ARMOUR or JUMP PACK model takes the space of two other models. If this model is equipped with a killkannon, it can only transport 12 models.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Transport Wound Track</th>
												<th>Wounds</th>
												<th>Move</th>
												<th>Strength</th>
												<th>Attacks</th>
											</tr>
											<tr>
												<td className="profile-name">Battlewagon1</td>
												<td>8-16+</td>
												<td>12&quot;</td>
												<td>8</td>
												<td>6</td>
											</tr>
											<tr>
												<td className="profile-name">Battlewagon2</td>
												<td>4-7</td>
												<td>9&quot;</td>
												<td>6</td>
												<td>D6</td>
											</tr>
											<tr>
												<td className="profile-name">Battlewagon3</td>
												<td>1-3</td>
												<td>6&quot;</td>
												<td>4</td>
												<td>D3</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Battlewagon (&apos;ard Case)</td>
												<td>*</td>
												<td>5+</td>
												<td>5+</td>
												<td>*</td>
												<td>8</td>
												<td>16</td>
												<td>*</td>
												<td>7</td>
												<td>4+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Zzap gun</td>
												<td>36&quot;</td>
												<td>Heavy 1</td>
												<td>2D6</td>
												<td>-3</td>
												<td>3</td>
												<td>Before firing this weapon, roll to determine the Strength of the shot. if the result is 11+ do not make a wound roll - instead, if the attack hits it causes 3 mortal wounds. The bearer then suffers a mortal wound.</td>
											</tr>
										</table>
									</li>
									<li className="rootselection">
										<h4>Deff Dreads [6 PL, 110pts]</h4>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Faction: &lt;Clan&gt;, Faction: Ork, Vehicle, Deff Dread, Heavy Support</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Dread Mob, Explodes</span>
										</p>
										<ul>
											<li>
												<h4>Deff Dread [6 PL, 110pts]</h4>
												<p>
													<span className="bold">Selections:</span> Dread Klaw [10pts], Dread Klaw [10pts], Rokkit Launcha [5pts], Skorcha [10pts]
												</p>
												<p className="profile-names">
													<span className="bold">Unit:</span> <span className="italic">Deff Dread</span>, <span className="bold">Weapon:</span> <span className="italic">Dread Klaw, Rokkit launcha, Skorcha</span>
												</p>
											</li>
										</ul>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Dread Mob</td>
												<td>The first time this unit is set up on the battlefield, all of its models must be placed within 6&quot; of at least one other model from the unit. From that point onwards, each operated independently and is treated as a separate unit for all rules purposes.</td>
											</tr>
											<tr>
												<td className="profile-name">Explodes</td>
												<td>If this model is reduced to 0 wounds, roll a D6 before removing it from the battlefield and before any embarked models disembark. On a 6 it explodes, and each unit within 3&quot; suffers D3 mortal wound.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Deff Dread</td>
												<td>6&quot;</td>
												<td>3+</td>
												<td>5+</td>
												<td>5</td>
												<td>7</td>
												<td>8</td>
												<td>2</td>
												<td>7</td>
												<td>3+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Dread Klaw</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>x2</td>
												<td>-3</td>
												<td>3</td>
												<td>Each time the bearer fights, it can make 1 additional attack with each dread klaw it is equipped with</td>
											</tr>
											<tr>
												<td className="profile-name">Rokkit launcha</td>
												<td>24&quot;</td>
												<td>Assault 1</td>
												<td>8</td>
												<td>-2</td>
												<td>3</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Skorcha</td>
												<td>8&quot;</td>
												<td>Assault D6</td>
												<td>5</td>
												<td>-1</td>
												<td>1</td>
												<td>This weapon automatically hits its target.</td>
											</tr>
										</table>
									</li>
									<li className="rootselection">
										<h4>Morkanaut [16 PL, 340pts]</h4>
										<p>
											<span className="bold">Selections:</span> Klaw of Gork (Or possibly Mork), Kustom Force Field [20pts], Kustom Mega-blasta, Kustom Mega-zappa, 2x Rokkit Launcha, 2x Twin Big Shoota
										</p>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Faction: &lt;Clan&gt;, Faction: Ork, Vehicle, Heavy Support, Morkanaut, Transport</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">&apos;Ere We Go!, Dakka Dakka Dakka</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Big &apos;n&apos; Stompy, Explodes, Kustom Force Field</span>, <span className="bold">Naut Wound Track:</span> <span className="italic">Morkanaut1, Morkanaut2, Morkanaut3</span>, <span className="bold">Transport:</span> <span className="italic">Morkanaut</span>, <span className="bold">Unit:</span> <span className="italic">Morkanaut</span>, <span className="bold">Weapon:</span>{" "}
											<span className="italic">Klaw of Gork (Crush), Klaw of Gork (Smash), Kustom Mega-blasta, Kustom Mega-zappa, Rokkit launcha, Twin Big Shoota</span>
										</p>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Big &apos;n&apos; Stompy</td>
												<td>This model is eligible to declare a charge in a turn in which it Fell Back .</td>
											</tr>
											<tr>
												<td className="profile-name">Explodes</td>
												<td>If this model is reduced to 0 wounds, roll a D6 before removing it from the battlefield. On a 6 it explodes, and each unit within 9&quot; suffers D6 mortal wounds.</td>
											</tr>
											<tr>
												<td className="profile-name">Kustom Force Field</td>
												<td>If this model is equipped with a kustom force field, friendly ORK units that are wholly within 9&quot; have a 5+ invulnerable save against ranged weapons.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Naut Wound Track</th>
												<th>Wounds</th>
												<th>Move</th>
												<th>WS</th>
												<th>Attacks</th>
											</tr>
											<tr>
												<td className="profile-name">Morkanaut1</td>
												<td>10-18+</td>
												<td>8&quot;</td>
												<td>3+</td>
												<td>4</td>
											</tr>
											<tr>
												<td className="profile-name">Morkanaut2</td>
												<td>5-9</td>
												<td>6&quot;</td>
												<td>4+</td>
												<td>3</td>
											</tr>
											<tr>
												<td className="profile-name">Morkanaut3</td>
												<td>1-4</td>
												<td>4&quot;</td>
												<td>5+</td>
												<td>2</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Transport</th>
												<th>Capacity</th>
											</tr>
											<tr>
												<td className="profile-name">Morkanaut</td>
												<td>This model can transport 6 FLASHGITZ or &lt;CLAN&gt; INFANTRY models. Each MEGAARMOUR or JUMP Pack model takes the space of two other models.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Morkanaut</td>
												<td>*</td>
												<td>*</td>
												<td>5+</td>
												<td>8</td>
												<td>8</td>
												<td>18</td>
												<td>*</td>
												<td>7</td>
												<td>3+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Klaw of Gork (Crush)</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>x2</td>
												<td>-4</td>
												<td>D6</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Klaw of Gork (Smash)</td>
												<td>Melee</td>
												<td>Melee</td>
												<td>User</td>
												<td>-2</td>
												<td>2</td>
												<td>Make 3 hit rolls for each attack made with this weapon instead of 1.</td>
											</tr>
											<tr>
												<td className="profile-name">Kustom Mega-blasta</td>
												<td>24&quot;</td>
												<td>Assault 1</td>
												<td>8</td>
												<td>-3</td>
												<td>D6</td>
												<td>If you roll one or more hit rolls of 1, the bearer suffers a mortal wound after all of the weapon&apos;s shots have been resolved</td>
											</tr>
											<tr>
												<td className="profile-name">Kustom Mega-zappa</td>
												<td>36&quot;</td>
												<td>Heavy 3D3</td>
												<td>8</td>
												<td>-3</td>
												<td>D6</td>
												<td>If you roll one or more hit rolls of 1 for this weapon, the bearer suffers a mortal wound after all of this weapon&apos;s shots have been resolved. Blast.</td>
											</tr>
											<tr>
												<td className="profile-name">Rokkit launcha</td>
												<td>24&quot;</td>
												<td>Assault 1</td>
												<td>8</td>
												<td>-2</td>
												<td>3</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Twin Big Shoota</td>
												<td>36&quot;</td>
												<td>Assault 6</td>
												<td>5</td>
												<td>0</td>
												<td>1</td>
												<td>-</td>
											</tr>
										</table>
									</li>
								</ul>
							</li>
							<li className="category">
								<h3>Flyer [10 PL, 205pts]</h3>
								<ul>
									<li className="rootselection">
										<h4>Wazbom Blastajet [10 PL, 205pts]</h4>
										<p>
											<span className="bold">Selections:</span> 2x Teleport Mega-Blasta [20pts], Smasha Gun, Stikkbomb Flinga [5pts], 2x Supa Shoota [20pts]
										</p>
										<p className="category-names">
											<span className="bold">Categories:</span> <span className="caps">Faction: &lt;Clan&gt;, Faction: Ork, Fly, Flyer, Vehicle, Wazbom Blastajet, Aircraft</span>
										</p>
										<p className="rule-names">
											<span className="bold">Rules:</span> <span className="italic">Airborne, Crash and Burn, Dakka Dakka Dakka, Hard to Hit</span>
										</p>
										<p className="profile-names">
											<span className="bold">Abilities:</span> <span className="italic">Mekbrain-enhanced Weapon-sights, Supersonic</span>, <span className="bold">Flier Wound Track:</span> <span className="italic">Blastajet1, Blastajet2, Blastajet3</span>, <span className="bold">Unit:</span> <span className="italic">Wazbom Blastajet</span>, <span className="bold">Weapon:</span> <span className="italic">Smasha Gun, Stikkbomb Flinga, Supa Shoota, Teleport Mega-Blasta</span>
										</p>
										<br />
										<table cellSpacing="-1">
											<tr>
												<th>Abilities</th>
												<th>Description</th>
											</tr>
											<tr>
												<td className="profile-name">Mekbrain-enhanced Weapon-sights</td>
												<td>This model can choose a single enemy unit each Shooting phase - add 1 to all hit rolls for attacks made against that unit with this model&apos;s smasha gun.</td>
											</tr>
											<tr>
												<td className="profile-name">Supersonic</td>
												<td>Each time this model moves, first pivot it on the spot up to 90 degrees (this does not contribute to how far the model moves), and then move the model straight forwards, Note that it cannot pivot again after the initial pivot. when the model Advances, increase its Move characteristic by 20&quot; until the end of the phase - do not roll a dice.</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Flier Wound Track</th>
												<th>Wounds</th>
												<th>Move</th>
												<th>BS</th>
												<th>Attacks</th>
											</tr>
											<tr>
												<td className="profile-name">Blastajet1</td>
												<td>7-12+</td>
												<td>20-60&quot;</td>
												<td>5+</td>
												<td>3</td>
											</tr>
											<tr>
												<td className="profile-name">Blastajet2</td>
												<td>4-6</td>
												<td>20-40&quot;</td>
												<td>6+</td>
												<td>D3</td>
											</tr>
											<tr>
												<td className="profile-name">Blastajet3</td>
												<td>1-3</td>
												<td>20-25&quot;</td>
												<td>6+</td>
												<td>1</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Unit</th>
												<th>M</th>
												<th>WS</th>
												<th>BS</th>
												<th>S</th>
												<th>T</th>
												<th>W</th>
												<th>A</th>
												<th>Ld</th>
												<th>Save</th>
											</tr>
											<tr>
												<td className="profile-name">Wazbom Blastajet</td>
												<td>*</td>
												<td>5+</td>
												<td>*</td>
												<td>6</td>
												<td>6</td>
												<td>12</td>
												<td>*</td>
												<td>6</td>
												<td>4+</td>
											</tr>
										</table>
										<table cellSpacing="-1">
											<tr>
												<th>Weapon</th>
												<th>Range</th>
												<th>Type</th>
												<th>S</th>
												<th>AP</th>
												<th>D</th>
												<th>Abilities</th>
											</tr>
											<tr>
												<td className="profile-name">Smasha Gun</td>
												<td>48&quot;</td>
												<td>Heavy D3</td>
												<td>*</td>
												<td>-4</td>
												<td>D6</td>
												<td>Instead of making a wound roll for this weapon, roll 2D6. If the result is equal to or greater than the target&apos;s Toughness, the attack successfully wounds. Blast.</td>
											</tr>
											<tr>
												<td className="profile-name">Stikkbomb Flinga</td>
												<td>12&quot;</td>
												<td>Assault 2D6</td>
												<td>3</td>
												<td>0</td>
												<td>1</td>
												<td>Blast</td>
											</tr>
											<tr>
												<td className="profile-name">Supa Shoota</td>
												<td>36&quot;</td>
												<td>Assault 3</td>
												<td>6</td>
												<td>-1</td>
												<td>1</td>
												<td>-</td>
											</tr>
											<tr>
												<td className="profile-name">Teleport Mega-Blasta</td>
												<td>24&quot;</td>
												<td>Assault 3</td>
												<td>8</td>
												<td>-2</td>
												<td>D3</td>
												<td>If a model suffers any unsaved wounds from this weapon and is not slain, roll a D6 at the end of the phase. if the result is greater than that model&apos;s Wounds characteristic, it is slain.</td>
											</tr>
										</table>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				</ul>

				<div className="summary">
					<h2>Force Rules</h2>
					<p>
						<span className="bold">Dis Is Ours! Zog Off!:</span>
						If your army is battle-forged, all Troops units in Ork Detachments gain this ability. Such a unit that is within range of an objective Markers (as specified in the mission) controls the objective marker even if there are more enemy models within range of that objective marker. If an enemy unit within range of the same objective marker has a similar ability, then the objective marker is controlled by the player who has the most models within range of it as normal.
					</p>
				</div>
				<div className="summary">
					<h2>Selection Rules</h2>
					<p>
						<span className="bold">&apos;Ere We Go!:</span>
						You can re-roll charge rolls for this unit. When doing so, you can re-roll all or any of the dice.
					</p>
					<p>
						<span className="bold">Airborne:</span>
						This model cannot charge, can only be charged by units that can FLY, and can only attack or be attacked in the Fight phase by units that can FLY.
					</p>
					<p>
						<span className="bold">Crash and Burn:</span>
						If this model is reduced to 0 wounds, roll a D6 before removing it from the battlefield. On a 6 it crashes and explodes, and each unit within 6&quot; suffers D3 mortal wounds.
					</p>
					<p>
						<span className="bold">Dakka Dakka Dakka:</span>
						Each time you roll an unmodified hit roll of 6 for an attack with a ranged weapon by a model in this unit, that hit roll succeeds regardless of modifiers.
						<br />
						<br />
						In addition, immediately make an additional hit roll against the same target using the same weapon. These additional hit rolls cannot themselves generate extra hit rolls. When firing a weapon with randomly determined characteristics (ie. Bubblechukka), any additional hit rolls use the same characteristics as the hit roll that generated the additional hit roll. This ability does not affect weapons that automatically hit their target.
					</p>
					<p>
						<span className="bold">Grots:</span>
						Units comprised entirely of GRETCHIN cannot benefit from any Clan Kultur. In addition, Ork Stratagems can only be used on these units if the Stratagem explicitly states so (e.g. the &apos;Grot Shields&apos; Stratagem)
					</p>
					<p>
						<span className="bold">Hard to Hit:</span>
						Your opponent must subtract 1 from hit rolls for attacks that target this model in the Shooting phase.
					</p>
					<p>
						<span className="bold">Mob Rule:</span>
						When using the Leadership characteristic of this unit, you can use either its own Leadership characteristic, or you can choose for the characteristic to be equal to either the number of models in the unit, or the number of models in another friendly unit within 6&quot; that has this ability.
					</p>
					<p>
						<span className="bold">Speed Mob:</span>
						The first time this unit sets up on the battlefield, all of its models must be placed within 6&quot; of at least one other model from the unit. From that point onwards each model operates independently and is treated as a separate unit for all rules purposes.
					</p>
				</div>
				<br />
				<p>
					Created with <a href="https://www.battlescribe.net">BattleScribe</a>
				</p>
			</div>
		</div>
	);
}

export default DUMMYITEM;
