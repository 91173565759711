import React from 'react'
import Header from '../Components/Header'

function NotFound() {
    return (
        <>
            <Header text={"Page Not Found"} />
            <div>
                Page Not Found
            </div>
        </>
    )
}

export default NotFound