import React from 'react'
import InfoToolTip from '../../../Components/InfoToolTip';

function SimulationAction({ content, tooltip, popup }) {
    return (
        <div>
            {content} {<InfoToolTip text={tooltip} popup={popup} />}
        </div>
    )
}

export default SimulationAction