import React from "react";

function Footer() {
	return (
		<div className="container mt-5">
			<footer className="pt-5">
				<div className="d-flex flex-column flex-sm-row justify-content-between pt-4 my-4 border-top">
					<p>
                        All rights regarding WarHammer 40k are reserved by Games Workshop. 
                        This is in no way affiliated with, nor supported by, Games Workshop. 
                        </p>
					<ul className="list-unstyled d-flex">
						<li className="ms-3">
							<a className="link-light" href="https://www.games-workshop.com/">
								Games Workshop
							</a>
						</li>
						<li className="ms-3">
							<a className="link-light" href="https://warhammer40000.com/">
								WarHammer 40,000
							</a>
						</li>
					</ul>
				</div>
			</footer>
		</div>
	);
}

export default Footer;
