import React from 'react'
import HL from './HLevel'
import LearnMore from './LearnMore'

function Header({ text, level = 1, link }) {
    const LEARN_MORE = link ? <LearnMore link={link} /> : <></>;
    const content = <>{text} {LEARN_MORE}</>
    return (
        <>
            <HL level={level} content={content} />
            {level === 1 ? <hr /> :  <br />}
        </>
    )
}

export default Header