import React from 'react'
import Header from '../Components/Header'
import Token from '../Helpers/Token'
import api from '../Helpers/API';


function Logout() {
    async function logout() {
        api({ func: 'logout' }).then(e => {
            Token.Reset();
            window.location.pathname = '';
        })
    } 
    logout();
    return (
        <div className='text-center'>
            <Header text={<>Log Out</>} />
            <br />

            <div className="spinner-border my-5" role="status">
                <span className="visually-hidden">Working...</span>
            </div>
        </div>
    )
}

export default Logout