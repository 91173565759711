import React from 'react'

import TerrainFeatures from '../Sections/TerrainFeatures'
import Header from '../../../Components/Header'

function Battlefield() {
    return (
        <div>
            <Header text={"Battlefield"} link={'/general/battlefield'} />
            <div>
                Data 
            </div> <br />

            <TerrainFeatures /> <br />
        </div>
    )
}

export default Battlefield