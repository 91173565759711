import React from 'react'

function Simulation() {
    return (
        <div>
            <h1>Simulation</h1> <hr />
        </div>
    )
}

export default Simulation