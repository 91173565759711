import React, { useEffect, useState } from 'react'
import { v4 as genId } from 'uuid'

import Module from '../module'
import API from '../../../Helpers/API'
import Header from '../../../Components/Header'
import CoreContent from '../../../Components/CoreContent'

function Core() {
    const [ factions, setFactions ] = useState([]);
    useEffect(() => {
        async function get() {
            API({ func: 'factions' })
            .then(({ data  }) => setFactions(data));
        }
        get()
    }, [ setFactions ])
    return (
        <div>
            <Header text={"Factions"} />

            <Header text={"Core Pages"} level={2} />
            {Module.Pages.map(({ Name, Path, Description }) => <CoreContent key={genId()} level={3} name={Name} content={Description} link={Module.Core + Path} />)}
            <br /> <br />

            <Header text={"Factions"} level={2} />
            {factions.map(({ id, name, description }) => <CoreContent key={genId()} level={3} name={name} content={description} link={`${Module.Core}/${id}`} />)}
        </div>
    )
}

export default Core