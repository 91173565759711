import React from 'react'
import Header from '../../../Components/Header'

function ObjectiveSecure({ level = 2 }) {
    return (
        <div>
            <Header text={"Objective Secured"} level={level}/>
            <div>
                Data
            </div> <br />
        </div>
    )
}

export default ObjectiveSecure