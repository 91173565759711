import React from 'react'

function HLevel({ level = 1, content }) {
    if (level === 1) return <h1> {content} </h1>
    else if (level === 2) return <h2> {content} </h2>
    else if (level === 3) return <h3> {content} </h3>
    else if (level === 4) return <h4> {content} </h4>
    else if (level === 5) return <h5> {content} </h5>
    else if (level === 6) return <h6> {content} </h6>
    else return <strong> {content} </strong>
}

export default HLevel