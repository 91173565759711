import React from 'react'
import { useParams } from 'react-router-dom'

import Data from '../Components/Instructions'
import Step from '../Components/Step'

function Instruction() {
    const { id } = useParams()
    // Get Data
    const num = parseInt(id) - 1 >= 0 ? (parseInt(id) - 1) % Data.length : Data.length - 1
    const data = Data[num]
    const { Name, Content } = data
    // Render Data
    return <Step number={num + 1} name={Name} content={Content} level={1} />
}

export default Instruction