import React, { useEffect, useRef } from 'react'
import Header from '../Components/Header'
import api from '../Helpers/API';

function Profile() {
    const name = useRef();
    const email = useRef();
    const pass = useRef();
    const pass_confirm = useRef();
    /**
     * Process
     *  1) On Load -> Get Values -> Set Values
     *  2) On Submit
     *      -> Get Values
     *      -> Validate Values
     *      -> Update Values 
     */

    useEffect(() => {
        api({ func: 'profile' }).then(data => console.log(data))
    }, [])

    return (
        <>
            <Header text={"Profile"} />
            <div data-bs-theme='dark'>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" className="form-control" ref={name} placeholder='Name' />
                </div>
                <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input type="email" className="form-control" ref={email} placeholder='Email' />
                </div>
                <div className="mb-3">
                    <label className="form-label">Password</label>
                    <input type="password" className="form-control" ref={pass} placeholder='Password' />
                </div>
                <div className="mb-3">
                    <label className="form-label">Confirm Password</label>
                    <input type="password" className="form-control" ref={pass_confirm} placeholder='Password Again' />
                </div>
                <div className="btn btn-outline-primary">Submit</div>
            </div>
        </>
    )
}

export default Profile