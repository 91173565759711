import React from 'react'

import Command from '../Pages/Command';
import NoAction from '../../../Components/NoAction';
import SimulationAction from '../Components/SimulationAction';

function CommandPhase({ info, step = 0 }) {
    let checked = false;
    if (Array.isArray(info) && info.length > 0 && info[0].value) checked = info[0].value

    if (step === 0) return <Input checked={checked} />        
    return <Action checked={checked} />
}

function Input({ checked }) {
    return (
        <>
            <h2> Command Phase - Input </h2>
            <div className='col-12'>
                <input type="checkbox" className="btn-check" id="battleForged" autoComplete="off" defaultChecked={checked}/>
                <label className="btn btn-outline-primary" htmlFor="battleForged">Army is Battle Forged</label><br />
            </div>
        </>
    )
}

function Action({ checked }) {
    return (
        <>
            <h2> Command Phase - Actions </h2>

            {checked && <SimulationAction content={"Gain 1 (CP)"} popup={<Command />} tooltip={"Before anything else, at the start of your Command phase"} />}
            {!checked && <NoAction />}
            <p className='py-4'>
                Some abilities found on datasheets and some Stratagems are used in your Command phase. <br />
                In addition, some missions have rules that take place in the Command phase. 
            </p>
        </>
    )
}

export default CommandPhase