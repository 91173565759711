import React from 'react'
import Header from '../../../Components/Header'

function Abilities() {
    return (
        <div>
            <Header text={"Abilities"} link={'/datasheet/abilities'} />
            <div>
                Data
            </div> <br />
        </div>
    )
}

export default Abilities