import React from 'react'
import { useParams } from 'react-router-dom'

import Header from '../../../Components/Header'

function Example() {
    const { id } = useParams()
    const data = Default();

    return (
        <div>
            <Header text={`Example ${data.id}`} link={`/missions/example/${id}`} />
            <div>
                Data
            </div> <br />
        </div>
    )
}

function Default() {
    return {
        id: 0
    }
}

export default Example