import React from 'react'
import { v4 as id } from 'uuid'

import Data from '../Components/Instructions'
import Header from '../../../Components/Header'
import Step from '../Components/Step'

function Instructions() {
    return (
        <div>
            <Header text={'Instructions'} link={'/missions/instructions'} />
            <div>
                Data 
            </div> <br />
            {Data.map(({ Name, Content }, i) => <Step key={id()} number={i + 1} name={Name} content={Content} />)}
        </div>
    )
}

export default Instructions