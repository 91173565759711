import React from 'react'
import HL from './HLevel'
import LearnMore from './LearnMore'

function CoreContent({ name, content, className, link, level = 2 }) {
    const heading = <>{link && <LearnMore link={link} />} {name} </>
    return (
        <div id={name} className={className}>
            <HL level={level} content={heading} />
            <p>{content}</p>
        </div>
    )
}

export default CoreContent