import React from 'react'

// import Movement from '../Pages/Movement'
// import SimulationAction from '../Components/SimulationAction'
import NoAction from '../../../Components/NoAction'


function MovementPhase({ info, step = 0 }) {
    // let checked = false;
    // if (Array.isArray(info) && info.length > 0 && info[0].value) checked = info[0].value

    if (step === 0) return <Input />        
    return <Action />
}

function Input() {
    return (
        <>
            <h2> Movement Phase - Input </h2>
            <div className='col-12'>
                <input type="checkbox" className="btn-check" id="battleForged" autoComplete="off"/>
                <label className="btn btn-outline-primary" htmlFor="battleForged">Army is Battle Forged</label><br />
            </div>
        </>
    )
}

function Action() {
    return (
        <>
            <h2> Movement Phase - Actions </h2>
            {/* {checked && <SimulationAction content={"Gain 1 (CP)"} popup={<Movement />} tooltip={"Before anything else, at the start of your Command phase"} />}
            {!checked &&  */}
            <NoAction />
            <p className='py-4'>
                Some abilities found on datasheets and some Stratagems are used in your Command phase. <br />
                In addition, some missions have rules that take place in the Command phase. 
            </p>
        </>
    )
}

export default MovementPhase