import React, { useState } from 'react'
import { Link } from 'react-router-dom' 
import { v4 as uuid } from 'uuid'

import Token from '../Helpers/Token'
import ExternalLink from '../Components/ExternalLink'

import Phases from '../Modules/Phases/module'
import GameStart from '../Modules/GameStart/module'
import Factions from '../Modules/Factions/module'
import General from '../Modules/General/module'
import Datasheet from '../Modules/Datasheet/module'
import Missions from '../Modules/Missions/module'

function ModulePages({ CoreName, Core, Return, Pages = [] }) {
  const classes = "list-group-item list-group-item-action d-flex justify-content-between align-items-center"
  return (
    <ul className="list-group list-group-flush" data-bs-theme="dark">
      <li key={uuid()} className={classes} onClick={Return}>
        <div className='dropdown-item' to={Core}><i className='fa fa-backward pe-2'></i> Back</div>
      </li>
      <li key={uuid()} className={classes} onClick={Return} data-bs-dismiss="modal" aria-label="Close">
        <Link className='dropdown-item' to={Core}>{CoreName} Core</Link>
      </li>
      {Pages.map(({ Name, Path }) => (
        <li key={uuid()} className={classes} onClick={Return} data-bs-dismiss="modal" aria-label="Close">
          <Link className='dropdown-item' to={Core + Path}>{Name}</Link>
        </li>
      ))}
    </ul>
  )
}

function Modules() {
  const [ active, setActive ] = useState(-1)
  const MODULES = [ Phases, GameStart, Factions, General, Datasheet, Missions ]
  const activeModule = MODULES[active]
  const classes = "list-group-item list-group-item-action d-flex justify-content-between align-items-center"
  
  return (
    <>
      {active === -1 ?
        <ul className="list-group list-group-flush" data-bs-theme="dark">
          {MODULES.map(({ Name, Pages }, i) => (
            <li key={uuid()} className={classes} onClick={() => setActive(i)}>
              <div className={`nav-link`} aria-current="page">{Name}</div>
              <span className="badge bg-secondary rounded-pill">{Pages.length}</span>
            </li>
          ))}
        </ul> :
        <ModulePages CoreName={activeModule.Name} Core={activeModule.Core} Pages={activeModule.Pages} Return={() => setActive(-1)} />
      }
    </>
  )
}

function Modal() {
  return (
    <div className="modal fade" id="modulesModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content text-bg-dark">
            <div className="modal-header" data-bs-theme="dark">
              <h1 className="modal-title fs-5">Internal Modules</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Modules />
            </div>
          </div>
        </div>
      </div>
  )
}




function ModuleDropdown({ Name = '', Pages = [], Core = '' }) {
  return (
    <div className='nav-item dropdown'>
      <div className='nav-link text-light dropdown-toggle' role="button" data-bs-toggle="dropdown" aria-expanded="false">
        {Name}
      </div>
      <ul className="dropdown-menu dropdown-menu-end">
        <li><Link className='dropdown-item' to={Core}> {Name} Core </Link></li>
        {Pages.map(page => (
          <li key={uuid()}><Link className='dropdown-item' to={Core + page.Path}> {page.Name} </Link></li>
        ))}
      </ul>
    </div>
  )
}

function ModuleDropdowns() {
  const MODULES = [ Phases, GameStart, Factions, General, Datasheet, Missions ]
  return MODULES.map(({ Name, Pages, Core }) => (
    <li key={uuid()}>
      <ModuleDropdown Name={Name} Pages={Pages} Core={Core} />
    </li>
  ))
}

function ExternalLinks() {
  return (
    <div className='nav-item dropdown'>
      <div className='nav-link text-light dropdown-toggle d-none d-sm-block' role="button" data-bs-toggle="dropdown" aria-expanded="false">
        External Resources
      </div>
      <div className='nav-link text-light dropdown-toggle d-block d-sm-none' role="button" data-bs-toggle="dropdown" aria-expanded="false">
        External
      </div>
      <ul className="dropdown-menu dropdown-menu-end">
        <li><ExternalLink className='dropdown-item' text={"Core Rules PDF"} to="https://www.warhammer-community.com/wp-content/uploads/2020/07/Lw4o3USx1R8sU7cQ.pdf" /></li>
        <li><ExternalLink className='dropdown-item' text={"Bootstrap Reference"} to="https://getbootstrap.com/docs/5.3/getting-started/introduction/" /></li>
        <li><ExternalLink className='dropdown-item' text={"Icon Reference"} to="https://fontawesome.com/icons" /></li>
      </ul>
    </div>
  )
}

function ProfileNav({ token }) {
  return (
    <div className="dropstart text-end">
      <div className="d-block text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="placeholder" 
          width="32" height="32" className="rounded-circle" />
      </div>
      <ul className="dropdown-menu text-small">
      { token ? 
        <>
          <li><Link className="dropdown-item" to={'/collections'}>Collections</Link></li>
          <li><Link className="dropdown-item" to={'/settings'}>Settings</Link></li>
          <li><Link className="dropdown-item" to={'/profile'}>Profile</Link></li>
          <li><hr className="dropdown-divider" /></li>
          <li><Link className='dropdown-item' to={'/logout'}>Logout</Link></li>
        </> :  
          <>
          <li><Link className='dropdown-item' to={"/login"}>Login</Link></li>
          <li><Link className='dropdown-item' to={"/register"}>Register</Link></li>
        </> 
      }
      </ul>
    </div>
  )
}

function NavBar() {
  const token = Token.Get();

  return (
    <div className='nav-container'>
      <header className='d-flex flex-wrap align-items-center justify-content-center justify-content-between py-3 mb-4' data-bs-theme="dark">
        <div className='col-auto mb-2 mb-md-0'>
          <Link className='navbar-brand' to={'/'}>
            <span className='fs-4'> Stratagem </span>
          </Link>
        </div>

        <ul className='nav d-none d-xl-flex mb-2 justify-content-center mb-md-0'>
          <ModuleDropdowns />
        </ul>
        <ul className='nav d-flex d-xl-none mb-2 justify-content-center mb-md-0'>
          <li>
            <div className="btn btn-success rounded-pill" data-bs-toggle="modal" data-bs-target="#modulesModal">View Modules</div>
          </li>
        </ul>

        <div className='col-auto text-end'>
          <ul className='nav col-12 col-md-auto mb-2 justify-content-end mb-md-0'>
            <li> <ExternalLinks /> </li>
            <li> <ProfileNav token={token} /> </li>
          </ul>
        </div>          
      </header>
      <Modal />
    </div>
  )
}

export default NavBar