import React, { useState } from 'react'
import Popup from './Popup'

function InfoToolTip({ text, popup }) {
    const [ popupStatus, setPopupStatus ] = useState(false);
    if (!popup) return ( <i data-bs-toggle="tooltip" title={text} className={`fa-solid fa-circle-info text-info`}></i> )
    function togglePopup () { setPopupStatus(!popupStatus); }

    return (
        <span>
            <i data-bs-toggle="tooltip" title={text} onClick={togglePopup} className={`fa-solid fa-circle-info text-info`}></i>
            <Popup content={popup} shown={popupStatus} />
        </span>
    )
}

export default InfoToolTip