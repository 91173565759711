import React from 'react'

function Popup({ content, shown }) {
    if (!shown) return (<></>)
    return (
        <div className='position-absolute card text-bg-secondary'>
            <div className='card-body'>
                {content}
            </div>
        </div>
    )
}

export default Popup