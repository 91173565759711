import React, { useState } from 'react'
import { v4 as genKey } from 'uuid'

import CommandPhase from '../Sections/CommandPhase';
import MovementPhase from '../Sections/MovementPhase';

// TODO: Add Player Based Turns??

function Simulation() {
    const [ Army, setArmy ] = useState({});
    const [ Phase, setPhase ] = useState(0);
    const [ Step, setStep ] = useState(0);
    const maxPhase = 7;

    function updateArmyInfo() {
        if (Phase === 0) {
            if (Step !== 0) return nextPhase();
            
            const isBattleForged = document.getElementById('battleForged').checked;
            setArmy([BuildArmyInfo('Battle Forged', isBattleForged)])
            setStep(1)
        } else if (Phase === 1) {
            //
        }
    }
    function nextPhase() {
        if (Phase + 1 >= maxPhase) return;
        setStep(0)
        setPhase(Phase + 1);
    }
    function prevPhase() {
        if (Step > 0) return setStep(Step - 1);
        if (Phase === 0) return;
        setPhase(Phase - 1);
        setStep(1);
    }

    return (
        <>
            <div className='row'>
                <h1 className='col-10'>Phases Simulation</h1> 
                <h3 className='col-2 text-end'>{Phase}</h3>
            </div>
            <hr />
            <div className='row'>
                <div className='col-2'>
                    {Object.keys(Army).length <= 0 && 'Army Information Goes Here'}
                    {Object.keys(Army).length > 0 && DisplayInfo(Army)}
                </div>
                <div className='col-10'>
                    <div className='row'>
                        {Phase === 0 && <CommandPhase info={Army} step={Step} />}
                        {Phase === 1 && <MovementPhase info={Army} step={Step} />}
                    </div>
                    <div className='row'>
                        {(Phase + Step) > 0 && 
                        <>
                            <label className='btn btn-outline-secondary col-1' onClick={prevPhase}>Back </label>
                            <div className='col-3'></div>
                        </>}
                        {(Phase + Step) <= 0 && <div className='col-4'></div>}
                        <label className='btn btn-outline-success col-4' onClick={updateArmyInfo}>
                            {Step === 0 ? "Submit" : "Next"} Phase
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}

function BuildArmyInfo(name, value) {
    const boolText = b => b ? "True" : "False";
    return {
        name,
        value,
        text: typeof value === "boolean" ? boolText(value) : value
    }
}

function DisplayInfo (data) {
    if (!Array.isArray(data)) return (<> No Info </>)

    return (
        <>
            {data.map(({ name, text }) => (
                <div key={genKey()}>
                    {name}: {text}
                </div>
            ))}
        </>
    )
}

export default Simulation