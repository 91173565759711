const Faction = {
    Name: "Factions",
    Core: '/factions',
    Pages: [
        { 
            Name: 'Simulation', 
            Path: '/simulation',
            Description: "",
        },
    ],
};

export default Faction