import React from 'react'
import Header from '../../../Components/Header'

function Composition() {
    return (
        <div>
            <Header text={"Composition and Wargear"} link={'/datasheet/composition'} />
            <div>
                Data
            </div> <br />
        </div>
    )
}

export default Composition