import React from 'react'
import { Routes, Route } from "react-router-dom"
import NotFound from '../../Pages/NotFound'

import Core from './Pages/Core'
import Instructions from './Pages/Instructions'
import Objectives from './Pages/Objectives'
import Example from './Pages/Example'
import Instruction from './Pages/Instruction'

function Missions() {
    return (
        <Routes>
            <Route path='/example/:id' element={<Example />} />
            <Route path='/objectives' element={<Objectives />} />
            <Route path='/instruction/:id' element={<Instruction />} />
            <Route path='/instructions' element={<Instructions />} />
            <Route path='/' element={<Core />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}

export default Missions