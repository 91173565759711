import React from 'react'
import Header from '../../../Components/Header'

function Charge() {
    return (
        <div>
            <Header text={"Charge Phase"} link={'/phases/charge'} />
            <div>
                Charge Phase
            </div>
        </div>
    )
}

export default Charge