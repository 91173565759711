import React from 'react'
import Header from '../../../Components/Header'

function Keywords({ heading_size = 2 }) {
    return (
        <>
            <Header text={"Keywords"} level={heading_size} />
            <div>
                Data
            </div>
        </>
    )
}

export default Keywords