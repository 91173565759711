import React from 'react'
import Header from '../../../Components/Header'

function Weapons() {
    return (
        <div>
            <Header text={"Weapons"} link={'/datasheet/weapons'} />
            <div>
                Data
            </div> <br />
        </div>
    )
}

export default Weapons