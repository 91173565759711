import React from 'react'
import Header from '../../../Components/Header'
import ObjectiveMarkers from '../Sections/ObjectiveMarkers'
import ObjectiveSecure from '../Sections/ObjectiveSecure'

function Objectives() {
    return (
        <div>
            <Header text={'Objectives'} link={'/missions/objectives'} />
            <div>
                Data 
            </div> <br />
            <ObjectiveMarkers />
            <ObjectiveSecure />
        </div>
    )
}

export default Objectives