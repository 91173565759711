import React from 'react'
import { Routes, Route } from "react-router-dom";

// Core Pages
import Home from '../Pages/Home';
import NotFound from '../Pages/NotFound';

// User Access 
import Register from '../Pages/Register'
import Validate from '../Pages/Validate';
import Logout from '../Pages/Logout';
import Login from '../Pages/Login'

// Users Profile 
import Collections from '../Pages/Collections';
import Settings from '../Pages/Settings';
import Profile from '../Pages/Profile';

// Modules
import Phases from '../Modules/Phases/Phases';
import GameStart from '../Modules/GameStart/GameStart';
import Factions from '../Modules/Factions/Factions';
import General from '../Modules/General/General';
import Datasheet from '../Modules/Datasheet/Datasheet';
import Missions from '../Modules/Missions/Missions';
import Collection from '../Pages/Collection';
import Token from '../Helpers/Token';

function RouteController() {
  const token = Token.Get();
  return (
    <Routes>
      {token ?
        <>
          <Route path='/collection/:id' element={<Collection />} />
          <Route path='/collections' element={<Collections />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/logout' element={<Logout />} />
        </> : 
        <>
          <Route path='/validate' element={<Validate />} />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
        </>
      }

      <Route path='/missions/*' element={<Missions />} />
      <Route path='/datasheet/*' element={<Datasheet />} />
      <Route path='/general/*' element={<General />} />
      <Route path='/factions/*' element={<Factions />} />
      <Route path='/starting/*' element={<GameStart />} />
      <Route path='/phases/*' element={<Phases />} />
      <Route path='/' element={<Home />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default RouteController