const Modules = [
    { 
        title: "General Rules", link: "/general", 
        content: "" 
    },
    { 
        title: "Game Start", link: "/starting", 
        content: "" 
    },
    { 
        title: "Phases", link: "/phases", 
        content: "" 
    },
    { 
        title: "Datasheet", link: "/datasheet", 
        content: "" 
    },
    { 
        title: "Missions", link: "/missions", 
        content: "" 
    },
    { 
        title: "Factions", link: "/factions", 
        content: "" 
    },
]

export default Modules