import React from 'react'
import Header from '../../../Components/Header'

function Fight() {
    return (
        <div>
            <Header text={"Fight Phase"} link={'/phases/fight'} />
            <div>
                Fight Phase
            </div>
        </div>
    )
}

export default Fight