import React from 'react'
import Header from '../../../Components/Header'

function HalfStrength({ heading_size = 2 }) {
    return (
        <>
            <Header text={"Half Strength"} level={heading_size}/>
            <div>
                Data
            </div>
        </>
    )
}

export default HalfStrength