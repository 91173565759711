import React from 'react'
import Header from '../../../Components/Header'

function DamageTables() {
    return (
        <div>
            <Header text={"Damage Tables"} link={'/datasheet/damageTables'} />
            <div>
                Data
            </div> <br />
        </div>
    )
}

export default DamageTables