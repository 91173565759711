import React from 'react'
import Header from '../../../Components/Header'

function Simulation() {
    return (
        <div>
            <Header text={"Simulation"} link={'/datasheet'} />
            <div>
                Data
            </div> <br />
        </div>
    )
}

export default Simulation