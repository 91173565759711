import React from 'react'
import { v4 as id } from 'uuid'

import Module from '../module'
import CoreContent from '../../../Components/CoreContent'
import Header from '../../../Components/Header'

function Core() {
    return (
        <div>
            <Header text={"Core Game Phases"} />

            {Module.Pages.map(({ Name, Path, Description }) => <CoreContent key={id()} name={Name} content={Description} link={Module.Core + Path} />)}
            <hr />
            <p>
                Once a player's turn has ended, their opponent then starts their
                turn. Once both players have completed a turn, the battle round has
                been completed and the next one begins, and so on, until the battle
                is concluded.
            </p>
        </div>
    )
}

export default Core