import React from 'react'
import Header from '../../../Components/Header'

import StartingStrength from '../Sections/StartingStrength'
import HStrength from '../Sections/HalfStrength'

function HalfStrength() {
    return (
        <div>
            <Header text={"Half Strength"} link={'/general/halfstrength'} />
            <div>
                Data
            </div> <br />

            <StartingStrength /> <br />
            <HStrength /> <br />
        </div>
    )
}

export default HalfStrength