import React from 'react'
import { Routes, Route } from "react-router-dom"
import  NotFound from '../../Pages/NotFound'

import Core from './Pages/Core'
import Simulation from './Pages/Simulation'

// Phases
import Command from './Pages/Command'
import Movement from './Pages/Movement'
import Psychic from './Pages/Psychic'
import Shooting from './Pages/Shooting'
import Charge from './Pages/Charge'
import Fight from './Pages/Fight'
import Morale from './Pages/Morale'

function Phases() {
  return (
    <Routes>
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<Core />} />
        <Route path='/simulation' element={<Simulation />} />
        <Route path='/command' element={<Command />} />
        <Route path='/movement' element={<Movement />} />
        <Route path='/psychic' element={<Psychic />} />
        <Route path='/shooting' element={<Shooting />} />
        <Route path='/charge' element={<Charge />} />
        <Route path='/fight' element={<Fight />} />
        <Route path='/morale' element={<Morale />} />
    </Routes>
  )
}

export default Phases