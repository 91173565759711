const Missions = {
    Name: "Missions",
    Core: '/missions',
    Pages: [
        { 
            Name: 'Instructions', Path: '/instructions',
            Description: "",
        },
        { 
            Name: 'Objectives', Path: '/objectives',
            Description: "",
        },
        { 
            Name: 'Examples', Path: '/example/0',
            Description: "",
        },
    ],
};

export default Missions