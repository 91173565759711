import React from 'react'
import { v4 as id } from 'uuid'

import Module from '../module'
import Header from '../../../Components/Header'
import CoreContent from '../../../Components/CoreContent'

function Core() {
    return (
        <div>
            <Header text={"Missions"} />
            {Module.Pages.map(({ Name, Path, Description }) => <CoreContent key={id()} name={Name} content={Description} link={Module.Core + Path} />)}
        </div>
    )
}

export default Core