import React from 'react'
import Header from '../../../Components/Header'

function Keywords() {
    return (
        <div>
            <Header text={"Keywords"} link={'/datasheet/keywords'} />
            <div>
                Data
            </div> <br />
        </div>
    )
}

export default Keywords