import React from 'react'
import Header from '../../../Components/Header'

function Step({ number, name, content, level = 2 }) {
    return (
        <div>
            <Header text={`${number}) ${name}`} level={level} />
            <div className='ps-4'>
                {content}
            </div> <br />
        </div>
    )
}

export default Step