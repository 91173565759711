import React from 'react'
import { Link } from 'react-router-dom'

function CollectionItem({ name, description, to }) {
    return (
        <div className='card text-center' style={{ maxWidth: '28rem' }}>
            <div className='card-header'>
                {name ?? 'Item Name'}
            </div>
            <div className='card-body'>
                <p className='card-text'>
                    {description ?? 'This is the Item'}
                </p>
            </div>
            <div className='card-footer'>
                <Link className='btn btn-light' to={to}> View Item </Link>
            </div>
        </div>
    )
}

export default CollectionItem