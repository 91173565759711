import React, { useRef } from 'react'
import Header from '../Components/Header'
import { Link } from 'react-router-dom'
import Token from '../Helpers/Token'
import api from '../Helpers/API'
import Swal from 'sweetalert2'

function ValidateEmail(email) {
    const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
    return regex.test(email)
}

function Login() {
    const email = useRef();
    const pass = useRef();

    function handleSubmit() {
        const data = {
            email: email.current.value,
            pass: pass.current.value,
        }
        if (!data.email || !ValidateEmail(data.email)) return alert("Invalid Email")
        if (!data.pass || data.pass.length <= 1) return alert("Invalid Password")

        api({ func: 'login', data }).then(({ success, data }) => {
            if (!success) return Swal.fire({ icon: 'error', title: 'Login Invalid' })
            Token.Set(data)
            window.location.pathname = '/' 
        })
    }

    return (
        <>
            <Header text={<div className='text-center'>Login</div>} />
            <div data-bs-theme="dark">
                <div className="mb-3 row">
                    <label className="col-sm-2 col-form-label">Email</label>
                    <div className="col-sm-10">
                        <input type="email" ref={email} className="form-control" />
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Password</label>
                    <div className="col-sm-10">
                        <input type="password" ref={pass} className="form-control"/>
                    </div>
                </div>
                <div className='text-center mt-4'>
                    <button type="submit" className="btn btn-outline-success col-4" onClick={handleSubmit}>Submit</button>
                    <Link className="btn btn-outline-link col-2" to={'/register'}>Register</Link>
                </div>
            </div>
        </>
    )
}

export default Login