import React from 'react'
import Header from '../../../Components/Header'

function Shooting() {
    return (
        <div>
            <Header text={"Shooting Phase"} link={'/phases/shooting'} />
            <div>
                Shooting Phase
            </div>
        </div>
    )
}

export default Shooting