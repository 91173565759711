const Datasheet = {
    Name: "Datasheet",
    Core: '/datasheet',
    Pages: [
        { 
            Name: 'Unit Name', Path: '/name', 
            Description: "", 
        },
        { 
            Name: 'Battlefield Role', Path: '/role', 
            Description: "", 
        },
        { 
            Name: 'Power Rating', Path: '/rating', 
            Description: "", 
        },
        { 
            Name: 'Profiles', Path: '/profiles', 
            Description: "", 
        },
        { 
            Name: 'Composition and Wargear', Path: '/composition', 
            Description: "", 
        },
        { 
            Name: 'Abilities', Path: '/abilities', 
            Description: "", 
        },
        { 
            Name: 'Weapons', Path: '/weapons', 
            Description: "", 
        },
        { 
            Name: 'Wargear Options', Path: '/wargear', 
            Description: "", 
        },
        { 
            Name: 'Keywords', Path: '/keywords', 
            Description: "", 
        },
        { 
            Name: 'Damage Tables', Path: '/damageTables', 
            Description: "", 
        },
        { 
            Name: 'Modifying Characteristics', Path: '/characteristics', 
            Description: "", 
        },
        { 
            Name: 'Simulation', Path: '/simulation',
            Description: "",
        },
    ],
};

export default Datasheet