import React from 'react'
import { Routes, Route } from "react-router-dom"
import  NotFound from '../../Pages/NotFound'

import Core from './Pages/Core'
import Missions from './Pages/Missions'
import Army from './Pages/Army'
import Battlefield from './Pages/Battlefield'
import Measuring from './Pages/Measuring'
import Dice from './Pages/Dice'
import HalfStrength from './Pages/HalfStrength'

function General() {
    return (
        <Routes>
            <Route path='*' element={<NotFound />} />
            <Route path='/' element={<Core />} />
            <Route path='/missions' element={<Missions />} />
            <Route path='/army' element={<Army />} />
            <Route path='/battlefield' element={<Battlefield />} />
            <Route path='/measuring' element={<Measuring />} />
            <Route path='/dice' element={<Dice />} />
            <Route path='/halfstrength' element={<HalfStrength />} />
        </Routes>
    )
}

export default General