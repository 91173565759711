import React from 'react'
import Header from '../Components/Header'
import { v4 as id } from  'uuid'

import Data from '../sections/Modules'
import Section from '../Components/Section'

function Home() {
  return (
    <>
      <div className='row text-center'>
        <Header text={"Warhammer 40k Stratagem"} />
      </div>
      <div>
        Data
      </div> <br />
      
      <div className='card-group' data-bs-theme="dark">
        {Data.map(({ title, link, content }) => <Section key={id()} title={title} link={link} content={content} />)}
      </div>
    </>
  )
}

export default Home