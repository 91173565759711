import React from 'react'
import Header from '../../../Components/Header'
import WhollyWithin from '../Sections/WhollyWithin'

function Measuring() {
    return (
        <div>
            <Header text={"Measuring Distances"} link={'/general/measuring'} />
            <div>
                Data
            </div> <br />

            <WhollyWithin /><br />
        </div>
    )
}

export default Measuring