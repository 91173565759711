import React from 'react'
import Header from '../Components/Header'

function Settings() {
    return (
        <>
            <Header text={"Setttings"} />
            <div>
                Data
            </div>
        </>
    )
}

export default Settings