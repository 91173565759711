import React from 'react'
import Header from '../../../Components/Header'

function Morale() {
    return (
        <div>
            <Header text={"Morale Phase"} link={'/phases/morale'} />
            <div>
            Morale Phase
            </div>
        </div>
    )
}

export default Morale