import axios from 'axios';
import Token from './Token';

/**
  * API Helper Function <br>
  * 
  *  Runs axios call based on parameters and handles the errors
  * @param {string} func    The URL of the api
  * @param {object} data    The data to pass
 */
async function api({ func, data = {} }) {
    //********  Step 1: Initialize Data  ********    
    // Setup Url
    let url = `https://stratagemapi.jdeldev.com/${func}`

    // Add Token
    const token = Token.Get()
    const output = (token && token.token) ? { token, data } : { data: data ?? ""}
    
    // Final Submit
    return await axios.post( url, { data: output } ).then(response => response.data)
}

export default api