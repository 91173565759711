import React from 'react'
import Header from '../../../Components/Header'

function Movement() {
    return (
        <div>
            <Header text={"Movement Phase"} link={'/phases/movement'} />

            <h2> First Move Units </h2>
            <div>
                <ol>
                    <li>Select One of Your Units</li>
                    <li>Select Unit</li>
                </ol>
            </div>
            
            <h2> Then Manage Reinforcements </h2>
            <div>

            </div>
        </div>
    )
}

export default Movement