import React from 'react'
import Header from '../../../Components/Header'
import Datasheet from '../Sections/Datasheet'
import Keywords from '../Sections/Keywords'

function Army() {
    return (
        <div>
            <Header text={"Army"} link={'/general/army'} />
            <div>
                Data
            </div> <br />

            <Datasheet /> <br />
            <Keywords /> <br />
            {/* Units */}
            {/* Unit Coherency */}
            {/* Engagement Range */}
        </div>
    )
}

export default Army