import React from 'react'
import Header from '../../../Components/Header'

function Psychic() {
    return (
        <div>
            <Header text={"Psychic Phase"} link={'/phases/psychic'} />
            <div>
                Psychic Phase
            </div>
        </div>
    )
}

export default Psychic