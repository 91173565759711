import React, { useEffect, useState } from 'react'
import Header from '../../../Components/Header'
import { useParams } from 'react-router-dom'
import API from '../../../Helpers/API'

function Faction() {
    const { id } = useParams();
    const [ faction, setFaction ] = useState({});

    useEffect(() => {
        API({ func: 'faction', data: { id: parseInt(id) } }).then(({ data }) => setFaction(data.filter(e => e.id === id)[0]))
    }, [ id, setFaction ])

    // console.log(faction)
    return (
        <div>
            <Header text={faction.name ?? 'Faction'} />
            <div>
                {faction.description && faction.description.length > 0 ? faction.description : "Data"}
            </div> <br />
        </div>
    )
}

export default Faction